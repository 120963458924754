ion-refresher,
ion-refresher-content {
  background-color: transparent;
}

.page-header {
  margin: 24px;

  h2 {
    text-align: center;
    margin: 0 0 12px;
    padding: 0;
    font-weight: bold;
  }

  p {
    text-align: center;
    font-size: 18px;
    line-height: 26px;
    margin: 0;
    padding: 0;
  }
}

.game-list-header {
  padding: 4px 0;
  text-align: center;
  color: var(--ion-color-light);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-family: "Ubuntu", sans-serif;
  border-bottom: 1px solid var(--ion-color-dark);
  background: linear-gradient(0deg, rgba(#333, 1) 0%, rgba(#555, 1) 100%);

  ion-label {
    margin: 0;
    padding: 0;
    width: 100%;
    font-weight: bold;
    text-align: center;
  }
}

.game-cell {
  background: var(--ion-color-dark);
  margin-bottom: 1px;
  text-align: center;

  ion-label {
    p {
      color: var(--ion-color-gray) !important;
    }
  }

  &:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

.numbers {
  position: relative;
  height: 50px;
  width: 50px;
  border-radius: 50px;

  .inner-numbers {
    height: 33px;
    width: 31px;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 20px;
    font-weight: bold;
    line-height: 20px;
    text-align: center;
    margin-top: 13.4px;
    margin-right: 9.6px;
  }
}

.one {
  background-color: #4aab51;
}

.two {
  background-color: #52747f;
}

.three {
  background-color: #5942a9;
}

.join-game-hero {
  text-align: center;
  border: 3px dotted var(--ion-color-secondary);
  border-radius: 8px;
  background: linear-gradient(0deg, rgba(#000, 0) 20%, rgba(#7763b8, 0.3) 100%);
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 12px;
  padding: 18px 0 10px 0;

  h2 {
    padding: 0 18px 10px;
    margin: 0;
    color: var(--ion-color-light);
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }

  .button {
    padding-left: 44px;
    padding-right: 44px;
  }
}

.alert-wrapper {
  background-color: var(--ion-color-dark) !important;
}

.alert-message {
  text-align: center !important;
  max-height: 100% !important;
}
