/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

.error {
  color: #f53d3d;
  font-size: 0.9em;
  padding: 6px 16px 0;
}

.timepicker {
  .timepicker__header {
    background-color: #5942a9 !important;
  }

  .timepicker__actions {
    width: 100% !important;
    background-color: #000 !important;

    div {
      width: 50% !important;
    }
  }

  .timepicker__body {
    background: radial-gradient(circle at top, #444 0%, #000 35%);
  }

  .clock-face__clock-hand {
    background-color: #5942a9 !important;

    &::after {
      background-color: #5942a9 !important;
    }

    &::before {
      border-color: #5942a9 !important;
    }
  }

  .clock-face {
    background: radial-gradient(circle at top, #444 0%, #000 35%);
  }

  .clock-face__number {
    > span.inactive {
      color: #fff !important;
      cursor: pointer;
    }
    > span.disabled {
      cursor: default !important;
      color: #383a3e !important;
    }

    > span {
      cursor: pointer;
      color: #fff !important;
    }
  }

  button:not(.timepicker-dial__item) {
    color: #fff !important;
    justify-content: center;
    align-items: center;
    background-color: #5942a9;
    width: 100% !important;
    border-right: 1px solid #000;
    border-left: 1px solid #000;

    &:hover {
      background-color: #473487 !important;
    }
  }
}

.alert-button.ion-focused.sc-ion-alert-ios,
.alert-button.ion-focused.sc-ion-alert-md,
.alert-tappable.ion-focused.sc-ion-alert-ios,
.alert-tappable.ion-focused.sc-ion-alert-md {
  /* Add your custom styles here */
  background-color: transparent;
}

.clearButton {
  display: none;
}

.li-ionic4-datePicker {
  .disabled {
    color: #111111;
  }
  .modal-wrapper {
    height: 470px;
    max-height: 100%;
    width: 312px;
    --ion-color-primary: #5942a9; // This Variable used for the change of colour ion-datepicker only.
    --ion-color-primary-tint: lighten(
      red
    ); // This Variable changes the tint colour of background when click button.

    ion-button {
      // --ion-color-primary: green; // This Variable used for the change colour of buttons only.
      // --ion-color-primary-tint: lightgreen; // This Variable changes the tint color of background when click buttons.
      // --border-radious: 0px; // This Variable changes the border radious of buttons.
      // button customization css write here...
    }
  }
}
ion-select, ion-datetime, .native-input {
  .datetime-time {
    overflow: hidden!important;
  }
}

.date-time-modal {
  .ion-delegate-host {
    background: black;
    border-color: black !important;
  }
  ion-button {
    margin: 0px!important;
    height: 3.5em;
    }
}

ion-datetime-button::part(native) {
  color: var(--color);
  border-radius: 0px;
  background-color: transparent;
  padding: 15px!important;
  font-size: 1rem!important;
  border: 1px solid var(--ion-color-gray)!important;
  width: 100%!important;
  text-align: left;
}

ion-datetime-button {
  width: 100%;

}

.custom-date-time {

  --ion-color-step-650: var(--ion-color-primary)!important;
  --ion-color-primary-rgb: 112, 68, 255;
  --ion-text-color: var(--ion-color-primary);
  --ion-color-step-500: var(--ion-color-light);
  --ion-text-color-rgb: 136, 19, 55;
  font-weight: bold!important;
  --background: black;
  color: var(--ion-color-secondary)!important;
  color: var(--ion-color-medium) !important;
  --ion-color-step-150: #eef5fa!important;
  --ion-color-primary: #5942a9!important; // This Variable used for the change of colour ion-datepicker only.
  --ion-color-secondary-tint: lighten(#5942a9)!important;
  --ion-color-primary-tint: lighten(
    #5942a9
  )!important; // This Variable changes the tint colour of background when click button.
  ion-label {
    --ion-color-primary: #5942a9; // This Variable used for the change of colour ion-datepicker only.
    --ion-color-primary-tint: lighten(
      #5942a9
    ); // This Variable changes the tint colour of background when click button.
  }

  .calendar-day-highlight {
    --background: red!important;
  }
}

.ion-datetime-button-overlay ion-datetime.datetime-grid {
  background: radial-gradient(circle at top, #444 0%, #000 35%);

  border-color: black!important;
  border-top-color: #5942a9!important;  
}

.cust-highlight {
  color: #633ce0 !important;
}
.winner {
  visibility: visible;
  opacity: 1;
  transition: opacity 3s linear;
}

.loser {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 3s, opacity 3s linear;
}

.phone-dialog {
  .alert-wrapper {
    min-height: 385px;
  }
  .alert-input-group {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .alert-message {
    padding-bottom: 0px;
    h1 {
      margin-top: 6px;
    }
    p {
      margin-top: 0px;
    }
    span {
      color: gold !important;
    }

    .cust-highlight {
      color: #633ce0 !important;
    }
    .winner {
      visibility: visible;
      opacity: 1;
      transition: opacity 3s linear;
    }

    .loser {
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s 3s, opacity 3s linear;
    }
  }
}

.fast-finger-alert .alert-message span {
  color: #ffd700ff !important;
  h1 {
    color: #ffd700ff !important;
  }
}

.fast-finger-alert .alert-message p {
  margin: 0px !important;
  img {
    height: 25px;
    width: 25px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 10px;
  }
}

ion-progress-bar {
  --background: #ffffff;
  --progress-background: #633ce0;
}

progress {
  color: #633ce0;
}

.colored-progress-bar {
  accent-color: #633ce0;
}

.round-end {
  .alert-wrapper {
    min-height: 385px;
  }
  .alert-message {
    margin-top: 23px;
    padding-bottom: 0px;

    .cust-highlight {
      color: #633ce0 !important;
    }
    .winner {
      visibility: visible;
      opacity: 1;
      transition: opacity 2s linear;
    }

    .loser {
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s 2s, opacity 2s linear;
    }
  }
}
.winner-dialog {
  .alert-wrapper {
    min-height: 385px;
  }
  .alert-message {
    padding-bottom: 0px;
    h1 {
      margin-top: 0px;
    }

    .winner {
      visibility: visible;
      opacity: 1;
      transition: opacity 2s linear;
    }

    .loser {
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s 2s, opacity 2s linear;
    }
    .cust-highlight {
      color: #633ce0 !important;
    }
  }
}

.flex {
  display: flex;
}

.fc {
  flex-direction: column;
}

.ac {
  align-items: center;
}

.jc {
  justify-content: center;
}

.action-sheet-group.sc-ion-action-sheet-ios {
  overflow: auto !important;
}

.center {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.hidden {
  display: none!important;
}
